<template>
	<div class="details">
		<div class="title">
			<p>{{ $route.query.type == 1 ? '卫生计生监督协管信息报告登记表' : '卫生计生监管巡查登记表' }}</p>
			<div class="right" v-if="$route.query.type == 1">
				<span>机构：{{ $route.query.name }}</span> <span>日期：{{ $route.query.year }}年</span>
			</div>
		</div>
		<el-table :data="tableData" border style="width: 100%" :height="tableHeight" v-if="$route.query.type == 2">
			<el-table-column type="index" width="50" label="序号"> </el-table-column>
			<el-table-column prop="spot" label="巡查地点与内容"> </el-table-column>
			<el-table-column prop="description" label="发现的主要问题"> </el-table-column>
			<el-table-column prop="inspectTime" label="巡查日期"> </el-table-column>
			<el-table-column prop="inspectorName" label="巡查人"> </el-table-column>
			<el-table-column prop="remark" label="备注"> </el-table-column>
		</el-table>
		<el-table :data="tableData" border style="width: 100%" :height="tableHeight" v-else>
			<el-table-column type="index" width="50" label="序号"> </el-table-column>
			<el-table-column prop="discoverTime" label="发现时间"> </el-table-column>
			<el-table-column prop="informationTypeDictionaryItem" label="信息类别">
				<template slot-scope="scope">
					{{ (scope.row.informationTypeDictionaryItem && scope.row.informationTypeDictionaryItem.title) || '-' }}
				</template>
			</el-table-column>
			<el-table-column prop="description" label="信息内容"> </el-table-column>
			<el-table-column prop="reportTime" label="报告时间"> </el-table-column>
			<el-table-column prop="reporterName" label="报告人"> </el-table-column>
		</el-table>
	</div>
</template>

<script>
export default {
	name: 'showDetails',
	components: {},
	data() {
		return {
			tableData: [],
		};
	},
	computed: {
		tableHeight() {
			return window.innerHeight - 200;
		},
	},
	mounted() {
		this.getList();
	},
	methods: {
		//获取列表
		getList() {
			let url = this.$route.query.type == 1 ? '/management-api/v1/PatrolSupervisions/' : '/management-api/v1/PatrolInspections/';
			this.$http
				.get(url + this.$route.query.id + '.json')
				.then((res) => {
					if (res.data && res.data.success) {
						this.tableData = res.data.collection || [];
					}
				})
				.catch((e) => {});
		},
	},
};
</script>

<style lang="scss" scoped>
.details {
	padding: 24px 40px;
	background-color: #fff;
	width: 90%;
	margin: 16px auto;
	.title {
		padding-left: 8px;
		font-size: 16px;
		line-height: 16px;
		height: 16px;
		color: #333;
		border-left: 3px solid #1db9b1;
		margin-bottom: 16px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		.right {
			height: 16px;
			span {
				color: #666666;
				font-size: 12px;
				&:first-child {
					margin-right: 16px;
				}
			}
		}
	}
	/deep/ .el-table {
		.cell {
			text-align: center;
			line-height: 20px;
		}
		.el-table__cell {
			padding: 13px 0;
		}
		th.el-table__cell {
			background-color: #f4f6fd;
			> .cell {
				color: #333;
				font-weight: 500;
			}
		}
	}
}
</style>